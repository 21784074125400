import { FC, useRef } from "react";
import { Heading } from "components/heading";
import { RelatedProductsSlide } from "./related-product-slide";
import { RelatedProductsSliderProps } from "../../../constants/types";
import { Slider } from "components/slider";
import { useMediaQuery } from "react-responsive";
import SliderNavButton from "../slider-nav-button";

export const RelatedProductsSlider: FC<RelatedProductsSliderProps> = ({
  attribute_code,
  attributes,
  highlightEntities,
  related,
  showLabel,
  title,
  background = "grey-triangle",
  ar = "ar11",
  groupBy = "id",
  level = "h3",
  selectBy,
  narrow = false,
}) => {
  const prevButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);
  const paginationWrapper = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 1200px)",
  });

  const groupedRelatedEntities = related.reduce<{
    [groupByValue: string]: RelatedProductsSliderProps["related"];
  }>((relatedObject, relatedEntity) => {
    if (relatedObject[relatedEntity[groupBy]]) {
      relatedObject[relatedEntity[groupBy]].push(relatedEntity);
    } else {
      relatedObject[relatedEntity[groupBy]] = [relatedEntity];
    }
    return relatedObject;
  }, {});

  const headingText =
    title ||
    (attributes &&
      attributes.find((attribute) => attribute.code === attribute_code)
        ?.label) ||
    "";

  return (
    <div className="relatedProducts">
      <Heading
        level={level}
        margin="mb-3"
        stylingLevel="h5"
        title={headingText}
        {...(headingText !== ""
          ? {
              decoration: "underline",
              stylingLevel: "h4",
            }
          : {
              decoration: "none",
              stylingLevel: "h5",
            })}
        contentRight={
          Object.keys(groupedRelatedEntities).length > 1 ? (
            <div className="related-products-meta">
              <div ref={paginationWrapper}></div>

              {isMobile ? (
                <div className="related-products-navigation">
                  <SliderNavButton ref={prevButton} role="prev" />
                  <SliderNavButton ref={nextButton} role="next" />
                </div>
              ) : null}
            </div>
          ) : null
        }
      />
      <Slider
        className="slider--relatedProducts"
        navigation={isMobile ? null : "default"}
        navNextButton={isMobile ? nextButton : null}
        navPrevButton={isMobile ? prevButton : null}
        pagination={true}
        paginationRef={paginationWrapper}
        slidesPerGroup={1}
        slidesPerView={1}
        spaceBetween={30}
        loop={false}
        breakpoints={
          narrow
            ? null
            : {
                1200: { slidesPerView: 2, slidesPerGroup: 2 },
              }
        }
        key={isMobile.toString()}
        virtual={false}
      >
        {Object.values(groupedRelatedEntities).map((relatedEntities, index) => (
          <RelatedProductsSlide
            slide={relatedEntities}
            selectBy={selectBy}
            ar={ar}
            background={background}
            key={relatedEntities[0].id + index}
            highlightEntities={highlightEntities}
            showLabel={showLabel}
          />
        ))}
      </Slider>
    </div>
  );
};
