import { ActionList } from "components/action-dropdown";
import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Col, Row } from "react-bootstrap";
import { entitiesConfig, getCallToActionLink } from "config/entity";
import { FunctionComponent, useState } from "react";
import { RelatedProductsSlideProps } from "../../../../constants/types";
import { sanitize } from "helpers/text-processing";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import Link from "next/link";
import ForceLocaleLink from "components/link";

export const RelatedProductsSlide: FunctionComponent<
  RelatedProductsSlideProps
> = ({
  slide,
  selectBy = "link_target_language",
  background = "grey-triangle",
  ar = "ar11",
  highlightEntities,
  showLabel,
}) => {
  const [
    kpButtonAction,
    liButtonAction,
    liAcademyButtonAction,
    solutionButtonAction,
    svButtonAction,
    serviceButtonAction,
  ] = useLabels(
    [entitiesConfig.kp.call_to_action_button._default, "Learn more"],
    [entitiesConfig.li.call_to_action_button._default, "Learn more"],
    [
      entitiesConfig.li.call_to_action_button.li_krohne_academy_online,
      "Learn more",
    ],
    [entitiesConfig.so.call_to_action_button._default, "Learn more"],
    [entitiesConfig.sv.call_to_action_button._default, "Learn more"],
    [entitiesConfig.service.call_to_action_button._default, "Learn more"],
  );

  const labelMapping = {
    Kp: kpButtonAction,
    Li: liButtonAction,
    LiAcademy: liAcademyButtonAction,
    So: solutionButtonAction,
    Sv: svButtonAction,
    Service: serviceButtonAction,
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentSlide = slide[currentIndex];

  const { country, language } = useLocale();
  const buttonUrl = getCallToActionLink(currentSlide) ?? "";

  const firstKeyVisual = currentSlide.key_visual?.[0] ?? currentSlide;

  if (
    currentSlide.family &&
    currentSlide.family[0]?.code === "li_krohne_academy_online"
  ) {
    if (
      firstKeyVisual &&
      "digital_asset_id" in firstKeyVisual &&
      firstKeyVisual.digital_asset_id !==
        "im-photo-composition/computer-monitor-only-with-blank-screen"
    ) {
      firstKeyVisual.digital_asset_transformation = `u_${
        firstKeyVisual.digital_asset_id?.replace?.("/", ":") || ""
      }.png,c_pad,ar_16:10,g_north_west,w_2512,x_344,y_112`;
      firstKeyVisual.digital_asset_id =
        "im-photo-composition/computer-monitor-only-with-blank-screen";
    }
  }

  const getEntityConfig = () => {
    let config = entitiesConfig[currentSlide.id.substr(0, 2)];

    if (!config) {
      config = entitiesConfig[currentSlide.id];
    }

    return config;
  };

  const target =
    getEntityConfig()?.call_to_action === "target" || highlightEntities
      ? "_blank"
      : "";

  return (
    <Row key={currentSlide.id}>
      <Col md={3}>
        {!!firstKeyVisual &&
          (firstKeyVisual.__typename === "Im" ||
            firstKeyVisual.__typename === "Li" ||
            firstKeyVisual.__typename === "Vi") && (
            <ForceLocaleLink
              href={buttonUrl}
              passHref={true}
              prefetch={false}
              target={target}
            >
              <Cloudinary
                media={firstKeyVisual}
                background={
                  firstKeyVisual.__typename !== "Li" ? background : null
                }
                ar={ar}
              />
            </ForceLocaleLink>
          )}
        {!firstKeyVisual && currentSlide.__typename === "Im" && (
          <Cloudinary media={currentSlide} background={background} ar={ar} />
        )}
        {highlightEntities?.find((e) => e === currentSlide.id) ? (
          <p className="background-gradient-secondary text-white p-1 text-nowrap">
            {showLabel}
          </p>
        ) : null}
      </Col>
      <Col md={9}>
        <ForceLocaleLink
          href={buttonUrl}
          passHref={true}
          prefetch={false}
          target={target}
        >
          <p
            className="h5"
            dangerouslySetInnerHTML={{
              __html: sanitize(currentSlide.title),
            }}
          />
        </ForceLocaleLink>
        <p
          className="h6 bold"
          dangerouslySetInnerHTML={{
            __html:
              "subtitle" in currentSlide && sanitize(currentSlide.subtitle),
          }}
        />
        <div
          className="styled-list-wrapper"
          dangerouslySetInnerHTML={{
            __html: "excerpt" in currentSlide && sanitize(currentSlide.excerpt),
          }}
        />
        <div className="mt-1">
          {Object.keys(slide).length > 1 && (
            <ActionList
              selected={currentSlide[selectBy][0]?.label}
              actions={Object.values(slide).map((entity, index) => {
                return {
                  title: entity[selectBy][0]?.label,
                  onClick: () => {
                    setCurrentIndex(index);
                  },
                };
              })}
              className="related-action-list mb-1"
            />
          )}
          {buttonUrl && (
            <Button
              variant="primary"
              label={
                labelMapping[
                  currentSlide.__typename +
                    (currentSlide.family
                      ? currentSlide.family[0]?.code ===
                        "li_krohne_academy_online"
                        ? "Academy"
                        : ""
                      : "")
                ]?.title
              }
              icon="caret-right-small"
              url={buttonUrl}
              locale={`${country}_${language}`}
              target={target}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};
